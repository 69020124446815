export async function findPage(contentType, locale, slug, additionalPopulate = []) {
  const { find } = useStrapi()

  const params = {
    locale: locale,
    populate: ['seo', 'seo.metaImage', 'faqs_block', 'faqs_block.faqs', 'faqs_block.employee', 'faqs_block.employee.picture', 'bottom_block'].concat(additionalPopulate),
  }

  if (slug) {
    params.filters = {
      slug: slug,
    }
  }

  const res = await find(contentType, params)

  const obj = Array.isArray(res.data) ? res.data[0] : res.data

  return obj?.attributes ?? {}
}

export async function findOnePage(contentType, locale, slug, additionalPopulate = []) {
  const { findOne } = useStrapi()

  const params = {
    locale: locale,
    populate: ['seo', 'seo.metaImage', 'faqs_block', 'faqs_block.faqs', 'faqs_block.employee', 'faqs_block.employee.picture', 'bottom_block'].concat(additionalPopulate),
  }
  let res

  try {
    res = await findOne(contentType, slug, params)
  }
  catch (err) {
    res = {}
  }

  const obj = Array.isArray(res.data) ? res.data[0] : res?.data

  return obj?.attributes ?? {}
}

export async function findArticles(contentType, locale) {
  const { find } = useStrapi()

  const params = {
    'locale': locale,
    'pagination[limit]': 3,
    'sort': 'id:desc',
    'populate': ['picture'],
  }

  const res = await find(contentType, params)

  return res?.data ?? {}
}
